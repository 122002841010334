html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  background: #f5f7fb;
  font-family: Roboto, sans-serif;
  touch-action: pan-x pan-y;
}

body {
  background: #18181a;
}

@tailwind components;
@tailwind utilities;
